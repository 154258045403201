// Needed for transparent backdrop.
.modal-backdrop {
  opacity: 0 !important;
}
.modal {
  background: rgba(0, 0, 0, .5);
}
.transparent-backdrop {
  background: rgba(0, 0, 0, 0) !important;
}
//   -------------------------------------------------------
//    Default Modal
//   -------------------------------------------------------
.default-modal {
  .modal-content {
    border: none;
    .modal-header {
      .modal-title {
        font-size: 1rem;
        font-weight: bold;
        small {
          display: block;
          font-size: .75rem !important;
          color: $text-light !important;
        }
      }
      .buttons {
        margin: 0 0 0 auto;
        .expand-modal, .close-modal {
          svg {
            @include transition();
            width: 20px !important;
            height: 20px !important;
            margin: 0 .25rem;
            color: $grayscale-5;
            opacity: .5;
            &:hover {
              opacity: .75;
              -o-transform: rotate(180deg);
              -moz-transform: rotate(180deg);
              -webkit-transform: rotate(180deg);
              -ms-transform: rotate(180deg);
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    .modal-body {
      padding: 2rem;
    }
    .n-modal-body {
      margin: 0 -2rem;
      padding: 0 2rem;
    }
  }
}
//   -------------------------------------------------------
//    Modals for databases
//   -------------------------------------------------------
.modal-body {
  h5 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }
  p {
    color: $text-light;
  }
}
//   -------------------------------------------------------
//    Right Side Modal
//   -------------------------------------------------------
.modal.right {
  padding-top: 120px;
}
.modal.right .modal-dialog {
  position: fixed;
  width: 50%;
  height: 100%;
  margin: auto;
  padding-bottom: 5%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
  border-radius: 0 !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.modal.right.fade .modal-dialog {
  right: -50%;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}
.modal.right.fade.show .modal-dialog {
  right: 0;
  top: 120px;
}
//   -------------------------------------------------------
//    Alert Messages - Toast
//   -------------------------------------------------------
.alert-cec {
  color: white;
  background-color: $teal-dark;
  border-color: $teal-dark;
}
.alert-cec-warning {
  color: $red;
  background: $red-very-soft;
  border-color: $red-soft;
}
.alert-cec-success {
  color: $teal !important;
  background: $teal-very-soft;
  border-color: $teal-soft;
}
.alert-cec-info {
  color: $blue !important;
  background: $blue-very-soft;
  border-color: $blue-soft;
}
.alert-cec-warning-yellow {
  color: $yellow-dark-text !important;
  background: $yellow-very-soft;
  border-color: $yellow;
}
//   -------------------------------------------------------
//    Alert Messages - Toast
//   -------------------------------------------------------
.Toastify {
  .bg-success {
    background: $notifications-success !important;
  }
  .bg-warning {
    background: $orange !important;
  }
  .bg-eanr-forms-warning {
    background: $yellow-dark !important;
  }
  .bg-error {
    background: $notifications-error !important;
  }
  .alert-progress {
    background: white !important;
  }
  .alert-text {
    color: white;
  }
  .alert-text-black {
    color: black;
  }
  .Toastify__toast {
    svg {
      margin-top: .75rem;
      color: white;
      &:hover {
        color: $grayscale-5;
      }
    }
  }
}
//   -------------------------------------------------------
//    Responsive
//   -------------------------------------------------------
@media(max-width: 991px)  {
  .modal-dialog {
    max-width: 90% !important;
  }
  .modal.right .modal-dialog {
    width: 100% !important;
  }
}
@media(max-width: 426px)  {
  .modal-dialog {
    max-width: 100% !important;
  }
  .modal.right {
    padding-top: 0 !important;
  }
  .modal.right.fade.show .modal-dialog {
    top: 0 !important;
    bottom: 0 !important;
    padding-bottom: 0 !important;
  }
}

//   -------------------------------------------------------
//    Green Modal - Deprecated
//   -------------------------------------------------------
.modal-green {
  .modal-content {
    .modal-header {
      background: $teal;
      .modal-title {
        small {
          color: $teal-soft !important;
        }
      }
    }
    .modal-footer {
      border-bottom: 5px solid $teal;
    }
  }
}
//   -------------------------------------------------------
//    Warning Modal - Deprecated
//   -------------------------------------------------------
.modal-warning {
  .modal-content {
    .modal-header {
      background: $red;
      .modal-title {
        small {
          color: $red-very-soft !important;
        }
      }
    }
    .modal-footer {
      border-bottom: 5px solid $red;
    }
  }
}
